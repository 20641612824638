<template>
  <div class="wrap" style="background-color: #404040;">
    <div class="querypage1">
      <div class="header">
        <img class="large" :src="$tenant.bigLogo" style="height: 40px;margin-top: 10px"/>
      </div>
      <div class="monitorcontainer1" style="height: auto">
        <div class="add" style="width: 100%; top: 60px; position: absolute;">
          {{$t("monitor.mark.position")}} &gt;
          <a style="cursor: pointer;">{{$t("route.singleMonitor")}}</a>
        </div>
        <div class="contentright" style="background-color: rgb(235, 235, 235); height: 100%">
          <el-container>
            <el-aside
              style="overflow: hidden;float:left;width: 605px;height: 100%;padding:10px;border: solid 1px #DCDFE6">
              <!-- eslint-disable-->
              <elevator :Car_Position="Car_Position" :Car_Direction="Car_Direction" :Door_Zone="Door_Zone"
                        :Door_Status="Door_Status" :Door_Switch_Status="Door_Switch_Status"
                        :Car_Status="Car_Status" :name="name" style="left:100px" :connectState="this.connectState "
                        :tValue="tValue" :beforeNum="beforeNum" :Fault_Status="Fault_Status"
                        :passengerStatus="passengerStatus"
                        :alarm-bell-status="alarmBell">
              </elevator>
              <div v-if="connectState === '连接断开'" class="frashButton">
                <!--<div class="frashButton" >- -->
                <el-button
                  style="background-image:url(/static/images/elevator/relink.png);background-size: 100% 100%;width: 125px;height: 50px;
                  text-align: right;color: #368de6;"
                  @click="reConnect()">
                  <span v-if="$t('lang') === 'zh'" style="font-size: 24px;margin-left: 15%;">
                    重连
                  </span>
                  <span v-else style="font-size: xx-small;display: block;margin-left: 27%">
                    Reconnection
                  </span>
                </el-button>
              </div>
              <div>
                <el-button v-if="!switchElevatorTag" class="changeButton" @click="changeElevator()">
                  <span v-if="$t('lang') === 'zh'" style="font-size: 17px;display: block;margin-top: 2%">
                    切换电梯
                  </span>
                  <span v-else style="font-size: xx-small;display: block;margin-top: -1%">
                    SwitchElevator
                  </span>
                </el-button>
                <img class="infobg" src="/static/images/elevator/infobg3.jpg"/>
                <div v-if="switchElevatorTag === true" class="switchElevatorDiv">
                  <img style="margin-left: 1330px" src="/static/images/elevator/guan-diao.png"
                       @click="closeChangeElevator()"/>
                  <table style="border:none;text-align: center">
                    <tr width="150">
                      <td width="10%">
                        <img
                          v-if=" currePage > 1"
                          src="/static/images/monitorCommon/xiang-zuo.png"
                          @click="findLast()"/>
                      </td>
                      <td v-for="(personnel,index) in useUnitElevators" :key="index" width="10%">
                        <img
                          v-if="personnel.tag === 0"
                          src="/static/images/elevator/elecolor.png"
                          class="pointer"
                          @click="selectElevator(personnel.id,index)"/>
                        <img v-if="personnel.tag ===1" src="/static/images/elevator/elecolor.png" class="pointer"/>
                      </td>
                      <td width="10%">
                        <img
                          v-if="currePage < totalPage"
                          src="/static/images/monitorCommon/xiang-you-.png"
                          @click="findNext()"/>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td v-for="(personnel,index) in useUnitElevators" :key="index">
                        <div>
                          {{personnel.no}}
                          <br>
                          {{personnel.name}}
                          <!--                          {{personnel.buildingNo}}{{$t("monitor.buildNum")}}{{personnel.ladderNo}}{{$t("monitor.ladderNum")}}-->
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              </div>
            </el-aside>
            <el-aside class="pageRight" style="width: 795px;padding: 0 1%;position: absolute;left: 549px">
              <el-row style="margin-top: 20px">
                <div style="display: flex;flex-direction: row;width:495px;margin-left: 0px">
                  <div style="margin: 25px 1% 0 0;width: 49%;text-align: center;">
                    <div v-if="Service_Mode===9" style="font-size:28px;color: #409EFF">
                      <span>{{currenStatus}}</span>
                      <span>{{Fault_Code}}</span>
                    </div>
                    <div v-else style="font-size:38px;color: #409EFF">{{currenStatus}}</div>
                  </div>
                  <div style="position:relative;width: 51%">
                    <img src="/static/images/elevator/layerbg.png" style="width: 100%"/>
                    <div style="position:absolute;z-indent:2;left:23%;top:17%;font-size:50px">
                      {{Car_Position}}
                    </div>
                    <div style="position:absolute; left:60%; top:3px;width: 32%;height: 32%;">
                      <div v-if="Car_Status === 0">
                        <img v-if="Car_Direction === 1" src="/static/images/elevator/bigup.png" style="width: 100%"/>
                        <img
                          v-else-if="Car_Direction === 2"
                          src="/static/images/elevator/bigdown.png"
                          style="width: 100%"/>
                      </div>
                      <div v-else>
                        <img v-if="Car_Direction === 1" src="/static/images/elevator/bigup.gif"/>
                        <img v-else-if="Car_Direction === 2" src="/static/images/elevator/bigdown.gif"/>
                      </div>
                    </div>
                  </div>
                </div>
              </el-row>
              <el-row>
                <el-col>
                  <div style="border: solid 1px #DCDFE6;">
                    <el-tabs v-model="activeName" :loading="loading" type="border-card">
                      <el-tab-pane :label="$t('monitor.monitor')" name="first">
                        <div style="height:475px;overflow-y:auto;overflow-x:hidden;">

                          <div style="height: 100px;width: 100%;">
                            <div style="padding-top: 25px;width: 180px;float: left;">
                              <div style="width: 80px;padding-left: 20px;float: left">
                                <img src="/static/images/elevator/open.png"/>
                              </div>
                              <div style="width: 80px;float: left">
                                <img v-if="Door_Switch_Status === 1" src="/static/images/elevator/onlight.png"/>
                                <img v-else src="/static/images/elevator/offlight.png"/>
                                <div v-if="Door_Switch_Status === 1" style="color: #368de6;font-size: 14px;">
                                  {{$t("monitor.status.open")}}
                                </div>
                                <div v-else style="font-size: 14px;">
                                  {{$t("monitor.status.open")}}
                                </div>
                              </div>
                            </div>
                            <div style="padding-top: 25px;width: 160px;float: left;">
                              <div style="width: 80px;float: left">
                                <img src="/static/images/elevator/close.png"/>
                              </div>
                              <div style="width: 80px;float: left">
                                <img v-if="Door_Switch_Status !== 1" src="/static/images/elevator/closelight.png"/>
                                <img v-else src="/static/images/elevator/offlight.png"/>
                                <div v-if="Door_Switch_Status !== 1" style="color: #368de6;font-size: 14px;">
                                  {{$t("monitor.status.close")}}
                                </div>
                                <div v-else style="font-size: 14px;">
                                  {{$t("monitor.status.close")}}
                                </div>
                              </div>
                            </div>
                            <div style="width: 280px;float: right;padding-right: 20px;">
                              <div id="chart" style="position: absolute;z-index: 0;width: 260px; height: 140px"></div>
                            </div>
                          </div>
                          <el-tabs v-model="activeName2">
                            <el-tab-pane :label="$t('elevator.elevator') + $t('elevator.status')" name="first">
                              <div class="singleMonitortable">
                                <!--第一个单元格垂直方向上合并，改变第一个单元格在垂直方向上的长度，变成原先的两倍，但是表格的高度和长度不变，所以要删除深圳所在的单元格（rowspan="2"意思是第一个单元格现在的长度等于原先两个单元格的长度）-->
                                <div class="Monitor">
                                  <i
                                    :class="Service_Mode !== 1 ? 'monitoricon mtricon-auto item2':'monitoricon mtricon-auto item3'"></i>
                                  <div :class="Service_Mode !== 1 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.motion")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Group_Control === false ? 'monitoricon mtricon-binglian item2':'monitoricon mtricon-binglian item3'"></i>
                                  <div :class="Group_Control === false ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.parallelControl")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Fault_Status === false ? 'monitoricon mtricon-guzhang item2':'monitoricon mtricon-guzhang item3'"></i>
                                  <div :class="Fault_Status === false ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.fault")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Service_Mode !== 2 ? 'monitoricon mtricon-repair_off item2':'monitoricon mtricon-repair_off item3'"></i>
                                  <div :class="Service_Mode !== 2 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.service")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Load_Status !== 2 ? 'monitoricon mtricon-chaozai item2':'monitoricon mtricon-chaozai item3'"></i>
                                  <div :class="Load_Status !== 2 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.overload")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Load_Status !== 1 ? 'monitoricon mtricon-manzai item2':'monitoricon mtricon-manzai item3'"></i>
                                  <div :class="Load_Status !== 1 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.loaded")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Safety_Circuit_Status !== 0 ? 'monitoricon mtricon-anquanhuilu item2':'monitoricon mtricon-anquanhuilu item3'"></i>
                                  <div :class="Safety_Circuit_Status !== 0 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.safety")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Service_Mode !== 4 ? 'monitoricon mtricon-xiaofangyuan item2':'monitoricon mtricon-xiaofangyuan item3'"></i>
                                  <div :class="Service_Mode !== 4 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.fire")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Service_Mode !== 11 ? 'monitoricon mtricon-duli item2':'monitoricon mtricon-duli item3'"></i>
                                  <div :class="Service_Mode !== 11 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.independence")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Service_Mode !== 10 ? 'monitoricon mtricon-suoti item2':'monitoricon mtricon-suoti item3'"></i>
                                  <div :class="Service_Mode !== 10 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.Lock")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Service_Mode !== 12 ? 'monitoricon mtricon-siji item2':'monitoricon mtricon-siji item3'"></i>
                                  <div :class="Service_Mode !== 12 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.driver")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="fireReturn !== 1 ? 'monitoricon mtricon-xiaofangfanhui item2':'monitoricon mtricon-xiaofangfanhui item3'"></i>
                                  <div :class="fireReturn !== 1 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.fireReturn")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="earthquakeModel !== 1 ? 'monitoricon mtricon-dizhenmoshi item2':'monitoricon mtricon-dizhenmoshi item3'"></i>
                                  <div :class="earthquakeModel !== 1 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.earthquakeModel")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="emergencyPower !== 1 ? 'monitoricon mtricon-yingjidianyuan item2':'monitoricon mtricon-yingjidianyuan item3'"></i>
                                  <div :class="emergencyPower !== 1 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.emergencyPower")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="serviceable !== 1 ? 'monitoricon mtricon-diantikefuwu item2':'monitoricon mtricon-diantikefuwu item3'"></i>
                                  <div :class="serviceable !== 1 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.serviceable")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="openButton !== 1 ? 'monitoricon mtricon-kaimenanniu item2':'monitoricon mtricon-kaimenanniu item3'"></i>
                                  <div :class="openButton !== 1 ? 'monitorFont2':'monitorFont3'">
                                    {{$t("monitor.status.openButton")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="netCtrl !== 1 ? 'monitoricon mtricon-lianwangkongzhi item2':'monitoricon mtricon-lianwangkongzhi item3'"></i>
                                  <div :class="netCtrl !== 1 ? 'monitorFont2':'monitorFont3'">
                                    {{$l("monitor.status.networkControl", "联网控制")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="!Door_Zone ? 'monitoricon mtricon-kaisuoquyu item2':'monitoricon mtricon-kaisuoquyu item3'"></i>
                                  <div :class="!Door_Zone ? 'monitorFont2':'monitorFont3'">
                                    {{$l("monitor.status.gateArea", "开锁区域")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="!Door_Open_Status ? 'monitoricon mtricon-kaimendaowei item2':'monitoricon mtricon-kaimendaowei item3'"></i>
                                  <div :class="!Door_Open_Status ? 'monitorFont2':'monitorFont3'">
                                    {{$l("monitor.status.openDoorInPlace", "开门到位")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="!Door_Status ? 'monitoricon mtricon-guanmendaowei item2':'monitoricon mtricon-guanmendaowei item3'"></i>
                                  <div :class="!Door_Status ? 'monitorFont2':'monitorFont3'">
                                    {{$l("monitor.status.closeTheDoorInPlace", "关门到位")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="!passengerStatus ? 'monitoricon mtricon-jiaoneiyouren item2':'monitoricon mtricon-jiaoneiyouren item3'"></i>
                                  <div :class="!passengerStatus ? 'monitorFont2':'monitorFont3'">
                                    {{$l("monitor.status.isAnyone", "轿内有人")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Service_Mode !== 7 ? 'monitoricon mtricon-diantikefuwu item2':'monitoricon mtricon-diantikefuwu item3'"></i>
                                  <div :class="Service_Mode !== 7 ? 'monitorFont2':'monitorFont3'">
                                    {{$l("monitor.status.currentService", "当前服务模式未知")}}
                                  </div>
                                </div>
                                <div class="Monitor">
                                  <i
                                    :class="Door_Switch_Status ? 'monitoricon mtricon-mensuohuilu item2':'monitoricon mtricon-mensuohuilu item3'"></i>
                                  <div :class="Door_Switch_Status ? 'monitorFont2':'monitorFont3'">
                                    {{$l("monitor.status.doorCircuit", "门锁回路")}}
                                  </div>
                                </div>
                              </div>
                            </el-tab-pane>
                            <el-tab-pane v-if="$i18n.isCn" :label="$l('monitor.status.fault','故障')" name="second">
                              <div class="fault">
                                <span v-if="Fault_Code === 2 || Fault_Code === 32"
                                      style="color: #368de6">{{$l("monitor.status.stopsOutside", "轿厢在开锁区域外停止")}}</span>
                                <span v-else>{{$l("monitor.status.stopsOutside", "轿厢在开锁区域外停止")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 2"
                                      style="color: #368de6">{{$l("monitor.status.duringOperation", "运行中开门")}}</span>
                                <span v-else>{{$l("monitor.status.duringOperation", "运行中开门")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 3"
                                      style="color: #368de6">{{$l("monitor.status.rushTop", "冲顶")}}</span>
                                <span v-else>{{$l("monitor.status.rushTop", "冲顶")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 4"
                                      style="color: #368de6">{{$l("monitor.status.squatBottom", "蹲底")}}</span>
                                <span v-else>{{$l("monitor.status.squatBottom", "蹲底")}}</span>
                              </div>
                              <div class="fault">
                                <span
                                  v-if="Fault_Code === 23 || Fault_Code === 87 || Fault_Code === 88 || Fault_Code === 100"
                                  style="color: #368de6">{{$l("monitor.status.speeding", "超速")}}</span>
                                <span v-else>{{$l("monitor.status.speeding", "超速")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 51"
                                      style="color: #368de6">{{$l("monitor.status.unexpectedMovement", "轿厢意外移动")}}</span>
                                <span v-else>{{$l("monitor.status.unexpectedMovement", "轿厢意外移动")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 32"
                                      style="color: #368de6">{{$l("monitor.status.safetyCircuitBreak", "安全回路断路")}}</span>
                                <span v-else>{{$l("monitor.status.safetyCircuitBreak", "安全回路断路")}}</span>
                              </div>
                              <div class="fault">
                                <span
                                  v-if="Fault_Code === 2 || Fault_Code === 37"
                                  style="color: #368de6">{{$l("monitor.status.circuitMalfunction", "层门锁回路故障")}}</span>
                                <span v-else>{{$l("monitor.status.circuitMalfunction", "层门锁回路故障")}}</span>
                              </div>
                              <div class="fault">
                                <span
                                  v-if="Fault_Code === 2 || Fault_Code === 37"
                                  style="color: #368de6">{{$l("monitor.status.circuitFault", "轿门锁回路故障")}}</span>
                                <span v-else>{{$l("monitor.status.circuitFault", "轿门锁回路故障")}}</span>
                              </div>
                              <div class="fault">
                                <span
                                  v-if="Fault_Code >= 2 && Fault_Code <= 69 "
                                  style="color: #368de6">{{$l("monitor.status.controlDevice", "电梯控制装置故障")}}</span>
                                <span v-else>{{$l("monitor.status.controlDevice", "电梯控制装置故障")}}</span>
                              </div>
                              <div class="fault">
                                <span
                                  v-if="Fault_Code === 81 || Fault_Code === 86 ||Fault_Code === 87 ||Fault_Code === 88 ||Fault_Code === 101 || Fault_Code === 105"
                                  style="color: #368de6">{{$l("monitor.status.machineFailure", "电梯曳引机故障")}}</span>
                                <span v-else>{{$l("monitor.status.machineFailure", "电梯曳引机故障")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code >= 71 && Fault_Code <= 120"
                                      style="color: #368de6">{{$l("monitor.status.inverterFailure", "变频器故障")}}</span>
                                <span v-else>{{$l("monitor.status.inverterFailure", "变频器故障")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 38 || Fault_Code === 35"
                                      style="color: #368de6">{{$l("monitor.status.systemFailure", "电梯制动系统故障")}}</span>
                                <span v-else>{{$l("monitor.status.systemFailure", "电梯制动系统故障")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 54"
                                      style="color: #368de6">{{$l("monitor.status.shortCircuit", "门锁电气装置短接")}}</span>
                                <span v-else>{{$l("monitor.status.shortCircuit", "门锁电气装置短接")}}</span>
                              </div>
                              <div class="fault">
                                <span
                                  v-if="Fault_Code === 26 || Fault_Code === 27 || Fault_Code === 28 || Fault_Code === 30|| Fault_Code === 68"
                                  style="color: #368de6">{{$l("monitor.status.levelingInductionFault", "平层感应故障")}}</span>
                                <span v-else>{{$l("monitor.status.levelingInductionFault", "平层感应故障")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 30"
                                      style="color: #368de6">{{$l("monitor.status.floorLocationLost", "楼层位置丢失")}}</span>
                                <span v-else>{{$l("monitor.status.floorLocationLost", "楼层位置丢失")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 20"
                                      style="color: #368de6">{{$l("monitor.status.limiterAction", "电动机运转时间限制器动作")}}</span>
                                <span v-else>{{$l("monitor.status.limiterAction", "电动机运转时间限制器动作")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 6"
                                      style="color: #368de6">{{$l("monitor.status.closeDoorFault", "关门故障")}}</span>
                                <span v-else>{{$l("monitor.status.closeDoorFault", "关门故障")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 5 || Fault_Code === 37"
                                      style="color: #368de6">{{$l("monitor.status.openDoorFault", "开门故障")}}</span>
                                <span v-else>{{$l("monitor.status.openDoorFault", "开门故障")}}</span>
                              </div>
                              <div class="fault">
                                <span v-if="Fault_Code === 2 || Fault_Code === 32"
                                      style="color: #368de6">{{$l("monitor.status.emergencyStopFault", "急停")}}</span>
                                <span v-else>{{$l("monitor.status.emergencyStopFault", "急停")}}</span>
                              </div>
                            </el-tab-pane>
                            <el-tab-pane v-else :label="$l('monitor.status.fault','故障')" name="second">
                              <div style="height: 250px;overflow-x:scroll;">
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 2 || Fault_Code === 32"
                                        style="color: #368de6">{{$l("monitor.status.stopsOutside", "轿厢在开锁区域外停止")}}</span>
                                  <span v-else>{{$l("monitor.status.stopsOutside", "轿厢在开锁区域外停止")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 2"
                                        style="color: #368de6">{{$l("monitor.status.duringOperation", "运行中开门")}}</span>
                                  <span v-else>{{$l("monitor.status.duringOperation", "运行中开门")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 3"
                                        style="color: #368de6">{{$l("monitor.status.rushTop", "冲顶")}}</span>
                                  <span v-else>{{$l("monitor.status.rushTop", "冲顶")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 4"
                                        style="color: #368de6">{{$l("monitor.status.squatBottom", "蹲底")}}</span>
                                  <span v-else>{{$l("monitor.status.squatBottom", "蹲底")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span
                                    v-if="Fault_Code === 23 || Fault_Code === 87 || Fault_Code === 88 || Fault_Code === 100"
                                    style="color: #368de6">{{$l("monitor.status.speeding", "超速")}}</span>
                                  <span v-else>{{$l("monitor.status.speeding", "超速")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 51"
                                        style="color: #368de6">{{$l("monitor.status.unexpectedMovement", "轿厢意外移动")}}</span>
                                  <span v-else>{{$l("monitor.status.unexpectedMovement", "轿厢意外移动")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 32"
                                        style="color: #368de6">{{$l("monitor.status.safetyCircuitBreak", "安全回路断路")}}</span>
                                  <span v-else>{{$l("monitor.status.safetyCircuitBreak", "安全回路断路")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span
                                    v-if="Fault_Code === 2 || Fault_Code === 37"
                                    style="color: #368de6">{{$l("monitor.status.circuitMalfunction", "层门锁回路故障")}}</span>
                                  <span v-else>{{$l("monitor.status.circuitMalfunction", "层门锁回路故障")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span
                                    v-if="Fault_Code === 2 || Fault_Code === 37"
                                    style="color: #368de6">{{$l("monitor.status.circuitFault", "轿门锁回路故障")}}</span>
                                  <span v-else>{{$l("monitor.status.circuitFault", "轿门锁回路故障")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span
                                    v-if="Fault_Code >= 2 && Fault_Code <= 69 "
                                    style="color: #368de6">{{$l("monitor.status.controlDevice", "电梯控制装置故障")}}</span>
                                  <span v-else>{{$l("monitor.status.controlDevice", "电梯控制装置故障")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span
                                    v-if="Fault_Code === 81 || Fault_Code === 86 ||Fault_Code === 87 ||Fault_Code === 88 ||Fault_Code === 101 || Fault_Code === 105"
                                    style="color: #368de6">{{$l("monitor.status.machineFailure", "电梯曳引机故障")}}</span>
                                  <span v-else>{{$l("monitor.status.machineFailure", "电梯曳引机故障")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code >= 71 && Fault_Code <= 120"
                                        style="color: #368de6">{{$l("monitor.status.inverterFailure", "变频器故障")}}</span>
                                  <span v-else>{{$l("monitor.status.inverterFailure", "变频器故障")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 38 || Fault_Code === 35"
                                        style="color: #368de6">{{$l("monitor.status.systemFailure", "电梯制动系统故障")}}</span>
                                  <span v-else>{{$l("monitor.status.systemFailure", "电梯制动系统故障")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 54"
                                        style="color: #368de6">{{$l("monitor.status.shortCircuit", "门锁电气装置短接")}}</span>
                                  <span v-else>{{$l("monitor.status.shortCircuit", "门锁电气装置短接")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span
                                    v-if="Fault_Code === 26 || Fault_Code === 27 || Fault_Code === 28 || Fault_Code === 30|| Fault_Code === 68"
                                    style="color: #368de6">{{$l("monitor.status.levelingInductionFault", "平层感应故障")}}</span>
                                  <span v-else>{{$l("monitor.status.levelingInductionFault", "平层感应故障")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 30"
                                        style="color: #368de6">{{$l("monitor.status.floorLocationLost", "楼层位置丢失")}}</span>
                                  <span v-else>{{$l("monitor.status.floorLocationLost", "楼层位置丢失")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 20"
                                        style="color: #368de6">{{$l("monitor.status.limiterAction", "电动机运转时间限制器动作")}}</span>
                                  <span v-else>{{$l("monitor.status.limiterAction", "电动机运转时间限制器动作")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 6"
                                        style="color: #368de6">{{$l("monitor.status.closeDoorFault", "关门故障")}}</span>
                                  <span v-else>{{$l("monitor.status.closeDoorFault", "关门故障")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 5 || Fault_Code === 37"
                                        style="color: #368de6">{{$l("monitor.status.openDoorFault", "开门故障")}}</span>
                                  <span v-else>{{$l("monitor.status.openDoorFault", "开门故障")}}</span>
                                </div>
                                <div class="faultEn">
                                  <span v-if="Fault_Code === 2 || Fault_Code === 32"
                                        style="color: #368de6">{{$l("monitor.status.emergencyStopFault", "急停")}}</span>
                                  <span v-else>{{$l("monitor.status.emergencyStopFault", "急停")}}</span>
                                </div>
                              </div>
                            </el-tab-pane>
                          </el-tabs>
                          <div style="height: 4px"></div>
                          <ul>
                            <li>
                              <div style="height: 38px;width: 90px;float: left">
                                <span v-if="$t('lang') === 'zh'" class="call-zh1">上召</span>
                                <span v-else class="call-en1">Calling Up</span>
                              </div>
                              <div style="height: 38px;width: 620px;float: left;overflow-x: auto;">
                                <div
                                  v-for="tab1 in Outer_Up_Orders"
                                  class="call-floor">
                                  &nbsp;{{tab1}}&nbsp;
                                </div>
                              </div>
                            </li>
                            <li>
                              <div style="height: 38px;width: 90px;float: left">
                                <span v-if="$t('lang') === 'zh'" class="call-zh1">下召</span>
                                <span v-else class="call-en1">Calling Down</span>
                              </div>
                              <div style="height: 38px;width: 620px;float: left;overflow-x: auto;">
                                <div
                                  v-for="tab2 in Outer_Down_Orders"
                                  class="call-floor">
                                  &nbsp;{{tab2}}&nbsp;
                                </div>
                              </div>
                            </li>
                            <li>
                              <div style="height: 38px;width: 90px;float: left">
                                <span v-if="$t('lang') === 'zh'" class="call-zh1">轿内</span>
                                <span v-else class="call-en1">Car Calling</span>
                              </div>
                              <div style="height: 38px;width: 620px;float: left;overflow-x: auto;">
                                <div
                                  v-for="tab3 in Inner_Orders"
                                  class="call-floor">
                                  &nbsp;{{tab3}}&nbsp;
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane :label="$t('monitor.information')" name="second">
                        <div class="info-item">
                          <span class="title">{{$t("elevator.name")}}：</span>
                          <span class="value">{{name}}</span>
                        </div>
                        <div class="info-item">
                          <span class="title">{{$t("monitor.check")}}：</span>
                          <span class="value">{{nextYearCheckDate}}</span>
                        </div>
                        <div class="info-item">
                          <span class="title">{{$t("monitor.maintenance")}}：</span>
                          <span class="value">{{nextMaintRecordDate}}</span>
                        </div>
                        <el-tabs v-model="activeName_" @tab-click="changeTabs">
                          <el-tab-pane :label="$t('monitor.elevator')" name="_first">
                            <div style="height: 310px;overflow:auto">
                              <div class="info-item">
                                <span class="title">{{$t("elevator.no")}}：</span>
                                <span class="value">
                                  <router-link :to="'/elevator/'+ elevatorId" target="_blank"><span
                                    style="color: #409EFF">{{no}}</span>
                                  </router-link>
                                </span>
                              </div>
                              <div class="info-item">
                                <span class="title">{{$t("elevator.name")}}：</span>
                                <span class="value">{{name}}</span>
                              </div>
                              <div class="info-item">
                                <span class="title">{{$t("elevator.elevator")}}{{$t("elevator.brand")}}:</span>
                                <span class="value">{{brand}}</span>
                              </div>
                              <div class="info-item">
                                <span class="title">{{$t("elevator.elevator")}}{{$t("elevator.model")}}:</span>
                                <span class="value">{{product}}</span>
                              </div>
                              <div class="info-item">
                                <span class="title">{{$t("elevator.elevatorType")}}:</span>
                                <span class="value" v-if="$i18n.isCn">{{elevatorProductName}}</span>
                                <span class="value" v-else>{{elevatorProductNameEn}}</span>
                              </div>
                              <div class="info-item">
                                <span class="title">{{$t("elevator.elevator")}}{{$t("developer.address")}}:</span>
                                <span class="value">{{address}}</span>
                              </div>
                              <div class="info-item">
                                <span class="title">{{$t("elevator.useUnit")}}:</span>
                                <span class="value">{{useUnitName}}</span>
                              </div>
                              <div class="info-item">
                                <span class="title">{{$t("elevator.maintenanced")}}:</span>
                                <span class="value">{{maintComName}}</span>
                              </div>
                              <div class="info-item">
                                <span class="title">{{$t("elevator.person")}}:</span>
                                <span class="value">{{maintEmpName}}</span>
                              </div>
                            </div>
                          </el-tab-pane>
                          <el-tab-pane :label="$t('elevator.emergencyRecord')" name="_second">
                            <div style="height: 310px;overflow:auto">
                              <el-table :data="workOrderRecordTableData" style="width: 100%;overflow: hidden">
                                <el-table-column prop="alarmTime" :label="$t('workOrder.alarmTime')"></el-table-column>
                                <el-table-column prop="workOrderNo" :label="$t('workOrder.workOrderNo')">
                                  <template slot-scope="scope">
                                    <router-link :to="'/workOrder/'+ scope.row.id" target="_blank">
                                      {{scope.row.workOrderNo}}
                                    </router-link>
                                  </template>
                                </el-table-column>
                              </el-table>
                            </div>
                          </el-tab-pane>
                          <el-tab-pane :label="$t('elevator.maintenanceRecord')" name="_third">
                            <div style="height: 310px;overflow:auto">
                              <el-table v-if="siteName === 'G-Cloud电梯云'" :data="maintRecordTableData"
                                        style="width: 100%;overflow: hidden">
                                <el-table-column prop="maintDate"
                                                 :label="$l('maintWorkOrder.maintDate','保养日期')"></el-table-column>
                                <el-table-column prop="maintStatus" :label="$l('maintWorkOrder.maintStatus','状态')">
                                  <template v-slot="scope">
                                    <el-tag v-if="scope.row.maintStatus === 0" type="danger">
                                      {{$l("maintWorkOrder.invalid", "作废")}}
                                    </el-tag>
                                    <el-tag v-else-if="scope.row.maintStatus === 1" type="warning">
                                      {{$l("maintWorkOrder.inPlan", "计划中")}}
                                    </el-tag>
                                    <el-tag v-else-if="scope.row.maintStatus === 2">
                                      {{$l("maintWorkOrder.notAccepted", "未接受")}}
                                    </el-tag>
                                    <el-tag v-else-if="scope.row.maintStatus === 3">
                                      {{$l("maintWorkOrder.accepted", "已接受")}}
                                    </el-tag>
                                    <el-tag v-else-if="scope.row.maintStatus === 4" type="info">
                                      {{$l("maintWorkOrder.signedIn", "已签到")}}
                                    </el-tag>
                                    <el-tag v-else-if="scope.row.maintStatus === 5" type="success">
                                      {{$l("maintWorkOrder.completed", "已完成")}}
                                    </el-tag>
                                  </template>
                                </el-table-column>
                              </el-table>
                              <el-table v-else :data="oldMaintRecordTableData" style="width: 100%;overflow: hidden">
                                <el-table-column prop="maintDate" :label="$t('maintWorkOrder.date')"></el-table-column>
                                <el-table-column prop="status" :label="$t('maintWorkOrder.status')">
                                  <template slot-scope="scope">
                                    <el-tag v-if="scope.row.status === 1">{{$t("maintWorkOrder.works")}}</el-tag>
                                    <el-tag v-if="scope.row.status === 2" type="danger">
                                      {{$t("maintWorkOrder.execution")}}
                                    </el-tag>
                                    <el-tag v-if="scope.row.status === 3" type="warning">
                                      {{$t("maintWorkOrder.stocks")}}
                                    </el-tag>
                                    <el-tag v-if="scope.row.status === 4" type="success">
                                      {{$t("maintWorkOrder.confirmed")}}
                                    </el-tag>
                                  </template>
                                </el-table-column>
                              </el-table>
                            </div>
                          </el-tab-pane>
                          <el-tab-pane :label="$t('workOrder.faultRecords')" name="_fourth">
                            <div style="height: 310px;overflow:auto">
                              <el-table :data="faultRecordTableData" style="width: 100%;overflow: hidden">
                                <el-table-column
                                  prop="faultTime"
                                  width="160"
                                  :label="$t('workOrder.faultTime')"></el-table-column>
                                <el-table-column
                                  prop="faultCode"
                                  :label="$t('faultTemplate.faultCode')"></el-table-column>
                                <el-table-column prop="faultDesc" :label="$t('potentialFault.faultDesc')">
                                  <template slot-scope="scope">
                                    <span
                                      v-if="scope.row.faultDesc === '000000' ">{{$t("workOrder.unKnowFault")}}</span>
                                    <span v-else>{{scope.row.faultDesc}}</span>
                                  </template>
                                </el-table-column>
                              </el-table>
                            </div>
                          </el-tab-pane>
                        </el-tabs>
                      </el-tab-pane>
                      <el-tab-pane :label="$t('monitor.parameter')" name="third">
                        <div style="height:475px;overflow-y:auto;overflow-x:hidden;">
                          <div style="height:460px;width: 48%;float: left">
                            <div class="info-item">
                              <span class="title">{{$t("monitor.parameters.times")}}:</span>
                              <span v-if="presentCounterValue === -1" class="value">-</span>
                              <span v-else class="value">{{presentCounterValue}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$t("monitor.parameters.time")}}:</span>
                              <span v-if="totalRunningTime === -1" class="value">-</span>
                              <span v-else class="value">{{getHM(totalRunningTime * 60)}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$t("monitor.parameters.wire")}}:</span>
                              <span v-if="wireropeBendingTimes === -1" class="value">-</span>
                              <span v-else class="value">{{wireropeBendingTimes}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$t("monitor.parameters.openTimes")}}:</span>
                              <span v-if="doorOpenCloseTimes === -1" class="value">-</span>
                              <span v-else class="value">{{doorOpenCloseTimes}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$l("monitor.status.runningDistance", "电梯运行距离")}}:</span>
                              <span v-if="totalRunDistance === -1" class="value">-</span>
                              <span v-else class="value">{{(totalRunDistance / 1000).toFixed(2) + "千米"}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$l("monitor.status.stationLayer", "基站层开门次数")}}:</span>
                              <span v-if="referenceFloorOpenTimes === -1" class="value">-</span>
                              <span v-else class="value">{{referenceFloorOpenTimes}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$l("monitor.status.numberOfDoorOpenings", "门开次数")}}:</span>
                              <span v-if="openTimes === -1" class="value">-</span>
                              <span v-else class="value">{{openTimes}}</span>
                            </div>
                          </div>
                          <div style="height:460px;width: 48%;margin-left: 2%;float: left">
                            <div class="info-item">
                              <span class="title">{{$l("monitor.status.touchpadActions", "安全触板动作次数")}}:</span>
                              <span v-if="safeTouchActionTimes === -1" class="value">-</span>
                              <span v-else class="value">{{safeTouchActionTimes}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$l("monitor.status.curtainActions", "光幕动作次数")}}:</span>
                              <span v-if="lightActionTimes === -1" class="value">-</span>
                              <span v-else class="value">{{lightActionTimes}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$l("monitor.status.lighting", "轿内照明点灯次数")}}:</span>
                              <span v-if="carLightTimes === -1" class="value">-</span>
                              <span v-else class="value">{{carLightTimes}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$l("monitor.status.lightingTime", "轿内照明点灯时间")}}:</span>
                              <span v-if="carLightRunningTime === -1" class="value">-</span>
                              <span v-else class="value">{{getHMS(carLightRunningTime)}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$l("monitor.status.downwardRelevelingTimes", "上行再平层次数")}}:</span>
                              <span v-if="upwardRelevelingTimes === -1" class="value">-</span>
                              <span v-else class="value">{{upwardRelevelingTimes}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$l("monitor.status.upwardRelevelingTimes", "下行再平层次数")}}:</span>
                              <span v-if="downRelevelingTimes === -1" class="value">-</span>
                              <span v-else class="value">{{downRelevelingTimes}}</span>
                            </div>
                            <div class="info-item">
                              <span class="title">{{$l("monitor.status.runSpeed", "运行速度")}}:</span>
                              <span v-if="runSpeed === '-1'" class="value">-</span>
                              <span v-else class="value">{{runSpeed + "m/s"}}</span>
                            </div>
                          </div>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane v-if="editAuth" :label="$l('monitor.status.instructionSettings','指令设置')"
                                   name="fourth">
                        <div style="height:475px;overflow-y:auto;overflow-x:hidden;">
                          <div class="info-item">
                            <span class="title">{{$l("monitor.status.IcCardAuthority", "IC卡权限")}}:</span>
                            <span class="value">
                              <el-button style="margin-left: 20px" type="text"
                                         @click="openDialog">{{$l("monitor.status.setting", "设置")}}</el-button>
                            </span>
                          </div>
                          <div class="info-item">
                            <span class="title">{{$l("monitor.status.networkControl", "联网控制")}}:</span>
                            <span v-loading="radioLoading" class="value">
                              <el-radio-group v-model="isAC" style="margin-left: 20px">
                                <el-radio :label="true">{{$l("monitor.status.Open", "开")}}</el-radio>
                                <el-radio :label="false">{{$l("monitor.status.Close", "关")}}</el-radio>
                              </el-radio-group>
                              <el-button :loading="radioLoading" style="margin-left: 20px" type="text"
                                         @click="setAC">{{$l("monitor.status.setting", "设置")}}
                              </el-button>
                            </span>
                          </div>
                          <div class="info-item">
                            <span class="title">{{$l("monitor.status.remoteLockElevator", "远程锁梯")}}:</span>
                            <span v-loading="lockLoading" class="value">
                              <el-radio-group v-model="lockStatus" style="margin-left: 20px">
                                <el-radio :label="1">{{$l("monitor.status.Open", "开")}}</el-radio>
                                <el-radio :label="0">{{$l("monitor.status.Close", "关")}}</el-radio>
                              </el-radio-group>
                              <el-button
                                :loading="lockLoading"
                                style="margin-left: 20px"
                                type="text"
                                @click="setLockStatus">{{$l("monitor.status.setting", "设置")}}
                              </el-button>
                            </span>
                          </div>
                          <el-dialog
                            :close-on-click-modal="false"
                            :visible.sync="dialogVisible"
                            :title="$l('monitor.status.IcCardAuthority','IC卡权限')"
                            width="800px"
                            top="10vh"
                            @close="dialogClose">
                            <div style="width: 100%;height: 100%;">
                              <el-form
                                ref="form"
                                :model="ICData"
                                class="form"
                                label-width="80px">
                                <div>
                                  <div
                                    style="width: 100%;margin-bottom: 5px;display: flex;flex-direction: row;justify-content: space-between">
                                    <div style="width: 80%">Loading
                                      <el-button :loading="submitLoading" type="primary" @click="getICData">
                                        {{$l("monitor.status.currentSettings", "读取当前设置")}}
                                      </el-button>
                                    </div>
                                    <div style="width: 150px">
                                      <el-button class="open" @click="allOpen">{{$l("monitor.status.fullyOpen", "全开")}}
                                      </el-button>
                                      <el-button class="close" @click="allClose">
                                        {{$l("monitor.status.allClosed", "全关")}}
                                      </el-button>
                                    </div>
                                    <div style="clear: both"></div>
                                  </div>
                                  <el-table
                                    v-loading="tabLoading"
                                    :data="ICData.list"
                                    element-loading-text="$l('monitor.status.inExecution','正在执行中')"
                                    element-loading-background="rgba(0, 0, 0, 0.1)"
                                    height="400"
                                    style="width: 100%">
                                    <el-table-column
                                      prop="WLfloor"
                                      align="center"
                                      :label="$l('monitor.status.physicalFloor','物理楼层')">
                                    </el-table-column>
                                    <el-table-column
                                      prop="XSfloor"
                                      align="center"
                                      :label="$l('monitor.status.showFloor','显示楼层')">
                                    </el-table-column>
                                    <el-table-column
                                      prop="inValue"
                                      align="center"
                                      :label="$l('monitor.status.elevatorCarCommand','轿内指令')">
                                      <template slot-scope="scope">
                                        <el-radio-group v-model="scope.row.inValue">
                                          <el-radio label="1">{{$l("monitor.status.Open", "开")}}</el-radio>
                                          <el-radio label="0">{{$l("monitor.status.Close", "关")}}</el-radio>
                                        </el-radio-group>
                                      </template>
                                    </el-table-column>
                                    <el-table-column
                                      prop="outValue"
                                      align="center"
                                      :label="$l('monitor.status.hallInstruction','厅外指令')">
                                      <template slot-scope="scope">
                                        <el-radio-group v-model="scope.row.outValue">
                                          <el-radio label="1">{{$l("monitor.status.Open", "开")}}</el-radio>
                                          <el-radio label="0">{{$l("monitor.status.Close", "关")}}</el-radio>
                                        </el-radio-group>
                                      </template>
                                    </el-table-column>
                                  </el-table>
                                </div>
                              </el-form>
                            </div>
                            <span slot="footer">
                              <el-button @click="dialogVisible=false">{{$l("monitor.status.cancel", "取消")}}</el-button>
                              <el-button :loading="submitLoading2" type="primary"
                                         @click="saveICData">{{$l("monitor.status.saveSettings", "保存设置")}}</el-button>
                            </span>
                          </el-dialog>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane v-if="showIBE" :label="$t('monitor.monitorIBE')" name="five">
                        <div style="height:475px;overflow-y:auto;overflow-x:hidden;">
                          <div style="width: 100%">
                            <el-button type="primary" size="mini" @click="$refs.iteIot.open()">Full Screen</el-button>
                          </div>
                          <div style="height:450px;width: 48%;float: left">
                            <div class="info-item" style="clear: right"><span
                              class="title">D1 3 Phase Supply:</span><span><img v-if="IBE.D1"
                                                                                class="image_light"><img
                              v-if="!IBE.D1&&IBE.D1!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">D2 Single Phase Supply:</span><span><img
                              v-if="IBE.D2" class="image_light"><img v-if="!IBE.D2&&IBE.D2!==null"
                                                                     class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">D3  RCD:</span><span><img v-if="IBE.D3"
                                                                                                 class="image_light"><img
                              v-if="!IBE.D3&&IBE.D3!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">D4  AC Supply:</span><span><img v-if="IBE.D4"
                                                                                                       class="image_light"><img
                              v-if="!IBE.D4&&IBE.D4!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">D5  DC Supply:</span><span><img v-if="IBE.D5"
                                                                                                       class="image_light"><img
                              v-if="!IBE.D5&&IBE.D5!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">D6  Door Supply Voltage:</span><span><img
                              v-if="IBE.D6" class="image_light"><img v-if="!IBE.D6&&IBE.D6!==null"
                                                                     class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">D7  Trap Door:</span><span><img v-if="IBE.D7"
                                                                                                       class="image_light"><img
                              v-if="!IBE.D7&&IBE.D7!==null" class="image_offlight"></span></div>

                            <div class="info-item"><span class="title">BDC1  EBOPS AC Supply:</span><span><img
                              v-if="IBE.BDC1" class="image_light"><img v-if="!IBE.BDC1 &&IBE.BDC1!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDC2  EBOPS DC Supply:</span><span><img
                              v-if="IBE.BDC2" class="image_light"><img v-if="!IBE.BDC2 &&IBE.BDC2!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDC3  EBOPS Charging Supply:</span><span><img
                              v-if="IBE.BDC3" class="image_light"><img v-if="!IBE.BDC3 &&IBE.BDC3!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDC4  EBOPS Charger Card:</span><span><img
                              v-if="IBE.BDC4" class="image_light"><img v-if="!IBE.BDC4 &&IBE.BDC4!==null"
                                                                       class="image_offlight"></span></div>

                            <div class="info-item"><span class="title">BDA1  Motor Temperature:</span><span><img
                              v-if="IBE.BDA1" class="image_light"><img v-if="!IBE.BDA1 &&IBE.IBE!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA2  Motor Noise:</span><span><img
                              v-if="IBE.BDA2"
                              class="image_light"><img
                              v-if="!IBE.BDA2 &&IBE.BDA2!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA3  Motor Vibration:</span><span><img
                              v-if="IBE.BDA3" class="image_light"><img v-if="!IBE.BDA3 &&IBE.BDA3!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA4  Resistor Temperature:</span><span><img
                              v-if="IBE.BDA4" class="image_light"><img v-if="!IBE.BDA4 &&IBE.BDA4!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA5  Sound Level:</span><span><img
                              v-if="IBE.BDA5"
                              class="image_light"><img
                              v-if="!IBE.BDA5 &&IBE.BDA5!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA6  Temperature:</span><span><img
                              v-if="IBE.BDA6"
                              class="image_light"><img
                              v-if="!IBE.BDA6 &&IBE.BDA6!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA7  L Door Panel:</span><span><img
                              v-if="IBE.BDA7"
                              class="image_light"><img
                              v-if="!IBE.BDA7 &&IBE.BDA7!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA8  L Cam Roller:</span><span><img
                              v-if="IBE.BDA8"
                              class="image_light"><img
                              v-if="!IBE.BDA8 &&IBE.BDA8!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA9  Car Cam Roller:</span><span><img
                              v-if="IBE.BDA9" class="image_light"><img v-if="!IBE.BDA9 &&IBE.BDA9!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA10  Trap Passenger:</span><span><img
                              v-if="IBE.BDA10" class="image_light"><img v-if="!IBE.BDA10 &&IBE.BDA10!==null"
                                                                        class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA11  Car Noise:</span><span><img
                              v-if="IBE.BDA11"
                              class="image_light"><img
                              v-if="!IBE.BDA11 &&IBE.BDA11!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA12 Car Vibration:</span><span><img
                              v-if="IBE.BDA12" class="image_light"><img v-if="!IBE.BDA12 &&IBE.BDA12!==null"
                                                                        class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA13  Gyroscope/ Tilt:</span><span><img
                              v-if="IBE.BDA13" class="image_light"><img v-if="!IBE.BDA13 &&IBE.BDA13!==null"
                                                                        class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA14  Lighting:</span><span><img
                              v-if="IBE.BDA14"
                              class="image_light"><img
                              v-if="!IBE.BDA14 &&IBE.BDA14!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA15  Smoking Detection:</span><span><img
                              v-if="IBE.BDA15" class="image_light"><img v-if="!IBE.BDA15 &&IBE.BDA15!==null"
                                                                        class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA16  Guide Rail Rollers:</span><span><img
                              v-if="IBE.BDA16" class="image_light"><img v-if="!IBE.BDA16 &&IBE.BDA16!==null"
                                                                        class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA17  Guide Lubrication Level:</span><span><img
                              v-if="IBE.BDA17" class="image_light"><img v-if="!IBE.BDA17 &&IBE.BDA17!==null"
                                                                        class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA18  Buffer Lubrication Level:</span><span><img
                              v-if="IBE.BDA18" class="image_light"><img v-if="!IBE.BDA18 &&IBE.BDA18!==null"
                                                                        class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA19  Buffer Pressure:</span><span><img
                              v-if="IBE.BDA19" class="image_light"><img v-if="!IBE.BDA19 &&IBE.BDA19!==null"
                                                                        class="image_offlight"></span></div>

                            <div class="info-item"><span
                              class="title">MA1  Motor Phase Voltage:</span><span
                              class="font_right">{{IBE.MA1}} V</span>
                            </div>
                            <div class="info-item"><span
                              class="title">MA2  Motor Phase Current:</span><span
                              class="font_right">{{IBE.MA2}} A</span>
                            </div>
                            <div class="info-item"><span class="title">MA3  Motor Frequency:</span><span
                              class="font_right">{{IBE.MA3}} Hz</span>
                            </div>
                            <div class="info-item"><span class="title">MA4  Motor Speed:</span><span
                              class="font_right">{{IBE.MA4}} r/min</span>
                            </div>
                            <div class="info-item"><span class="title">MA5  Motor Torque:</span><span
                              class="font_right">{{IBE.MA5}} N.m</span>
                            </div>
                            <div class="info-item"><span class="title">MA6  Drive DC Voltage:</span><span
                              class="font_right">{{IBE.MA6}} V</span>
                            </div>
                            <div class="info-item"><span class="title">MD1  Control Circuit Supply:</span><span><img
                              v-if="IBE.MD1" class="image_light"><img v-if="!IBE.MD1&&IBE.MD1!==null"
                                                                      class="image_offlight"></span></div>

                            <div class="info-item"><span class="title">MD2  Circuit Continuity:</span><span><img
                              v-if="IBE.MD2" class="image_light"><img v-if="!IBE.MD2&&IBE.MD2!==null"
                                                                      class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD3  Abnormal Start/ Stops:</span><span><img
                              v-if="IBE.MD3" class="image_light"><img v-if="!IBE.MD3 &&IBE.MD3!==null"
                                                                      class="image_offlight"></span></div>
                          </div>
                          <div style="height:450px;width: 48%;margin-left: 2%;float: left">
                            <div class="info-item"><span class="title">MD4  Cyclic Acceleration:</span><span><img
                              v-if="IBE.MD4" class="image_light"><img v-if="!IBE.MD4 &&IBE.MD4!==null"
                                                                      class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD5  Cyclic Deceleration:</span><span><img
                              v-if="IBE.MD5" class="image_light"><img v-if="!IBE.MD5 &&IBE.MD5!==null"
                                                                      class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD6  Drive Time:</span><span
                              class="font_right">{{IBE.MD6}} min</span></div>
                            <div class="info-item"><span class="title">MD7  Motor Start/ Stop:</span><span><img
                              v-if="IBE.MD7" class="image_light"><img v-if="!IBE.MD7 &&IBE.MD7!==null"
                                                                      class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD8  LD Open:</span><span><img v-if="IBE.MD8"
                                                                                                      class="image_light"><img
                              v-if="!IBE.MD8 &&IBE.MD8!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD9  LD Close:</span><span><img v-if="IBE.MD9"
                                                                                                       class="image_light"><img
                              v-if="!IBE.MD9 &&IBE.MD9!==null" class="image_offlight"></span></div>

                            <div class="info-item"><span class="title">MD10  LD Cycle:</span><span
                              class="font_right">{{IBE.MD10}}</span></div>
                            <div class="info-item"><span class="title">MD11  LD Hold:</span><span><img v-if="IBE.MD11"
                                                                                                       class="image_light"><img
                              v-if="!IBE.MD11 &&IBE.MD11!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span
                              class="title">MD12  LD Interlock Switch:</span><span><img v-if="IBE.MD12"
                                                                                        class="image_light"><img
                              v-if="!IBE.MD12 &&IBE.MD12!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD13  Open:</span><span><img v-if="IBE.MD13"
                                                                                                    class="image_light"><img
                              v-if="!IBE.MD13 &&IBE.MD13!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD14  Close:</span><span><img v-if="IBE.MD14"
                                                                                                     class="image_light"><img
                              v-if="!IBE.MD14 &&IBE.MD14!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD15  Hold:</span><span><img v-if="IBE.MD15"
                                                                                                    class="image_light"><img
                              v-if="!IBE.MD15 &&IBE.MD15!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD16  Waiting Time:</span><span
                              class="font_right">{{IBE.MD16}} s</span>
                            </div>
                            <div class="info-item"><span class="title">MD17  Fault:</span><span><img v-if="IBE.MD17"
                                                                                                     class="image_light"><img
                              v-if="!IBE.MD17 &&IBE.MD17!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD18  Car Communication:</span><span><img
                              v-if="IBE.MD18" class="image_light"><img v-if="!IBE.MD18 &&IBE.MD18!==null"
                                                                       class="image_offlight"></span></div>

                            <div class="info-item"><span class="title">MD19  Loading:</span><span><img v-if="IBE.MD19"
                                                                                                       class="image_light"><img
                              v-if="!IBE.MD19 &&IBE.MD19!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD20  Level/ Position:</span><span
                              class="font_right">{{IBE.MD20}}</span></div>
                            <div class="info-item"><span class="title">MD21  Movement Up:</span><span><img
                              v-if="IBE.MD21"
                              class="image_light"><img
                              v-if="!IBE.MD21 &&IBE.MD21!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD22  Movement Down:</span><span><img
                              v-if="IBE.MD22"
                              class="image_light"><img
                              v-if="!IBE.MD22 &&IBE.MD22!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD23  Hall Button:</span><span><img
                              v-if="IBE.MD23"
                              class="image_light"><img
                              v-if="!IBE.MD23 &&IBE.MD23!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD24  Hall Indicator:</span><span><img
                              v-if="IBE.MD24" class="image_light"><img v-if="!IBE.MD24 &&IBE.MD24!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">MD25  Brake On/ Off:</span><span
                              class="font_right">{{IBE.MD25}}</span>
                            </div>
                            <div class="info-item"><span
                              class="title">MD26  Brake Interval Time:</span><span
                              class="font_right">{{IBE.MD26}} s</span>
                            </div>
                            <div class="info-item"><span class="title">BDA20  Lift Breakdown 24/7:</span><span><img
                              v-if="IBE.BDA20" class="image_light"><img v-if="!IBE.BDA20 &&IBE.BDA20!==null"
                                                                        class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA21  Jerkiness:</span><span><img
                              v-if="IBE.BDA21"
                              class="image_light"><img
                              v-if="!IBE.BDA21 &&IBE.BDA21!==null" class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA22  Irregular Acceleration:</span><span><img
                              v-if="IBE.BDA22" class="image_light"><img v-if="!IBE.BDA22 &&IBE.BDA22!==null"
                                                                        class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA23  Irregular Velocity:</span><span><img
                              v-if="IBE.BDA23" class="image_light"><img v-if="!IBE.BDA23 &&IBE.BDA23!==null"
                                                                        class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDA24  Numbers of Vibration:</span><span
                              class="font_right">{{IBE.BDA24}}</span></div>
                            <div class="info-item"><span
                              class="title">BDA25  Number of Trips:</span><span class="font_right">{{IBE.BDA25}}</span>
                            </div>
                            <div class="info-item"><span
                              class="title">BDA26  Trip patterns by Floor, Time and Day:</span>
                              <table class="vm-table" style="width: 100%;margin-top: 14px">
                                <tr style="height: 30px">
                                  <td style="width: 14%;">One</td>
                                  <td style="width: 14%;">Two</td>
                                  <td style="width: 14%;">Three</td>
                                  <td style="width: 14%;">Four</td>
                                  <td style="width: 14%;">Five</td>
                                  <td style="width: 14%;">Six</td>
                                  <td style="width: 14%;">Seven</td>
                                </tr>
                                <tr style="height: 30px" v-if="IBE.BDA26!==null && IBE.BDA26">
                                  <td>{{IBE.BDA26[0]}}</td>
                                  <td>{{IBE.BDA26[1]}}</td>
                                  <td>{{IBE.BDA26[2]}}</td>
                                  <td>{{IBE.BDA26[3]}}</td>
                                  <td>{{IBE.BDA26[4]}}</td>
                                  <td>{{IBE.BDA26[5]}}</td>
                                  <td>{{IBE.BDA26[6]}}</td>
                                </tr>
                                <tr style="height: 30px" v-else>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </table>
                            </div>
                            <div class="info-item"><span
                              class="title">BDA27  Distance Travelled:</span><span
                              class="font_right">{{IBE.BDA27}} m</span>
                            </div>
                            <div class="info-item"><span class="title">BDA28  Travel Time:</span><span
                              class="font_right">{{IBE.BDA28}} min</span>
                            </div>
                            <div class="info-item"><span class="title">BDA29  Idle Time:</span><span
                              class="font_right">{{IBE.BDA29}} min</span>
                            </div>
                            <div class="info-item"><span class="title">BDE1  MR Temperature:</span><span><img
                              v-if="IBE.BDE1" class="image_light"><img v-if="!IBE.BDE1 &&IBE.BDE1!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDE2 MR Pressure:</span><span><img
                              v-if="IBE.BDE2" class="image_light"><img v-if="!IBE.BDE2 &&IBE.BDE2!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDE3 MR Humidity:</span><span><img
                              v-if="IBE.BDE3" class="image_light"><img v-if="!IBE.BDE3 &&IBE.BDE3!==null"
                                                                       class="image_offlight"></span></div>
                            <div class="info-item"><span class="title">BDE4 Car-Urine Detection:</span><span><img
                              v-if="IBE.BDE4" class="image_light"><img v-if="!IBE.BDE4 &&IBE.BDE4!==null"
                                                                       class="image_offlight"></span></div>

                          </div>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane v-if="false" :label="$t('monitor.monitorA4Io')" name="six">
                        <div style="height:475px;overflow-y:auto;overflow-x:hidden;">
                          <div v-if="false" style="width: 100%">
                            <el-button type="primary" size="mini" @click="$refs.a4Iot.open()">Full Screen</el-button>
                          </div>
                          <div style="width:100%;margin:0 auto;">
                            <div class="info-item-title">主控制板输入口状态</div>
                            <div class="info-item1">
                              <span class="title">X0 检修1:</span>
                              <span>
                                <img :class="a4.X0 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X1 检修2:</span>
                              <span>
                                <img :class="a4.X1 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X2 检修上行:</span>
                              <span>
                                <img :class="a4.X2 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X3 检修下行:</span>
                              <span>
                                 <img :class="a4.X3 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X4 上限位:</span>
                              <span>
                                 <img :class="a4.X4 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X5 下限位:</span>
                              <span>
                                 <img :class="a4.X5 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X6 上平层:</span>
                              <span>
                                 <img :class="a4.X6 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X7 下平层:</span>
                              <span>
                                 <img :class="a4.X7 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X8 上一减速:</span>
                              <span>
                                 <img :class="a4.X8 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X9 下一减速:</span>
                              <span>
                                 <img :class="a4.X9 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X10 上二减速:</span>
                              <span>
                                 <img :class="a4.X10 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X11 下二减速:</span>
                              <span>
                                 <img :class="a4.X11 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X12 上三减速:</span>
                              <span>
                                 <img :class="a4.X12 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X13 下三减速:</span>
                              <span>
                                 <img :class="a4.X13 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X14 上四减速:</span>
                              <span>
                                 <img :class="a4.X14 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X15 下四减速:</span>
                              <span>
                                 <img :class="a4.X15 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X16 门区开关:</span>
                              <span>
                                 <img :class="a4.X16 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X17 开门再平层或提前开门继电器检测:</span>
                              <span>
                                 <img :class="a4.X17 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X18 消防返回:</span>
                              <span>
                                 <img :class="a4.X18 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X19 消防员:</span>
                              <span>
                                 <img :class="a4.X19 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X20 调速器进线接触器检测:</span>
                              <span>
                                 <img :class="a4.X20 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X21 调速器出线接触器检测:</span>
                              <span>
                                 <img :class="a4.X21 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X22 抱闸接触器检测:</span>
                              <span>
                                 <img :class="a4.X22 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X23 抱闸开关1:</span>
                              <span>
                                 <img :class="a4.X23 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X24 抱闸开关2:</span>
                              <span>
                                 <img :class="a4.X24 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X25 电动机过热保护:</span>
                              <span>
                                 <img :class="a4.X25 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X26 安全回路继电器检测:</span>
                              <span>
                                 <img :class="a4.X26 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X27 门锁回路继电器检测:</span>
                              <span>
                                 <img :class="a4.X27 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X28 变频器运行信号检测:</span>
                              <span>
                                 <img :class="a4.X28 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X29 变频器故障信号:</span>
                              <span>
                                 <img :class="a4.X29 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X30 地震:</span>
                              <span>
                                 <img :class="a4.X30 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X31 停电应急平层:</span>
                              <span>
                                 <img :class="a4.X31 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X32 后备电源:</span>
                              <span>
                                 <img :class="a4.X32 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X33 封星检测:</span>
                              <span>
                                 <img :class="a4.X33 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X34 端站开关:</span>
                              <span>
                                 <img :class="a4.X34 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X36 安全回路:</span>
                              <span>
                                 <img :class="a4.X36 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X37 门锁回路:</span>
                              <span>
                                 <img :class="a4.X37 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">X38 厅门锁回路:</span>
                              <span>
                                 <img :class="a4.X38 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                          </div>
                          <div style="width:100%;margin:0 auto;">
                            <div class="info-item-title">轿厢板输入口状态</div>
                            <div class="info-item1">
                              <span class="title">GX0 前门开门按钮:</span>
                              <span>
                                <img :class="a4.GX0 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX1 前门关门按钮:</span>
                              <span>
                                <img :class="a4.GX1 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX2 前门开门保持按钮:</span>
                              <span>
                                <img :class="a4.GX2 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX3 后门开门按钮:</span>
                              <span>
                                 <img :class="a4.GX3 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX4 后门关门按钮:</span>
                              <span>
                                 <img :class="a4.GX4 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX5 后门开门保持按钮:</span>
                              <span>
                                 <img :class="a4.GX5 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX8 司机换向:</span>
                              <span>
                                 <img :class="a4.GX8 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX9 司机:</span>
                              <span>
                                 <img :class="a4.GX9 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX10 独立:</span>
                              <span>
                                 <img :class="a4.GX10 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX11 直驶:</span>
                              <span>
                                 <img :class="a4.GX11 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX12 消防员:</span>
                              <span>
                                 <img :class="a4.GX12 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX13 非服务层设置:</span>
                              <span>
                                 <img :class="a4.GX13 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX14 前门选择开关:</span>
                              <span>
                                 <img :class="a4.GX14 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GX15 后门选择开关:</span>
                              <span>
                                 <img :class="a4.GX15 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                          </div>
                          <div style="width:100%;margin:0 auto;">
                            <div class="info-item-title">轿顶板输入口状态</div>
                            <div class="info-item1">
                              <span class="title">HX0 前门开门到位:</span>
                              <span>
                                <img :class="a4.HX0 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX1 前门关门到位:</span>
                              <span>
                                <img :class="a4.HX1 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX2 前门堵转:</span>
                              <span>
                                <img :class="a4.HX2 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX3 前门光幕:</span>
                              <span>
                                 <img :class="a4.HX3 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX4 前门安全触板:</span>
                              <span>
                                 <img :class="a4.HX4 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX5 后门开门到位:</span>
                              <span>
                                 <img :class="a4.HX5 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX6 后门关门到位:</span>
                              <span>
                                 <img :class="a4.HX6 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX7 后门堵转:</span>
                              <span>
                                 <img :class="a4.HX7 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX8 后门光幕:</span>
                              <span>
                                 <img :class="a4.HX8 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX9 后门安全触板:</span>
                              <span>
                                 <img :class="a4.HX9 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX10 超载:</span>
                              <span>
                                 <img :class="a4.HX10 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX11 满载:</span>
                              <span>
                                 <img :class="a4.HX11 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HX12 轻载:</span>
                              <span>
                                 <img :class="a4.HX12 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                          </div>
                          <div style="width:100%;margin:0 auto;">
                            <div class="info-item-title">主控制板输出口状态</div>
                            <div class="info-item1">
                              <span class="title">Y0 抱闸接触器输出:</span>
                              <span>
                                <img :class="a4.Y0 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y1 抱闸强激接触器输出:</span>
                              <span>
                                <img :class="a4.Y1 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y2 调速器进线接触器输出:</span>
                              <span>
                                <img :class="a4.Y2 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y3 调速器出线接触器输出:</span>
                              <span>
                                 <img :class="a4.Y3 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y4 提前开门输出:</span>
                              <span>
                                 <img :class="a4.Y4 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y5 消防指示:</span>
                              <span>
                                 <img :class="a4.Y5 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y6 停电应急平层完成:</span>
                              <span>
                                 <img :class="a4.Y6 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y8 前门开门:</span>
                              <span>
                                 <img :class="a4.Y8 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y9 前门关门:</span>
                              <span>
                                 <img :class="a4.Y9 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y10 前门强迫关门:</span>
                              <span>
                                 <img :class="a4.Y10 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y11 后门开门:</span>
                              <span>
                                 <img :class="a4.Y11 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y12 后门关门:</span>
                              <span>
                                 <img :class="a4.Y12 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y13 后门强迫关门:</span>
                              <span>
                                 <img :class="a4.Y13 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y16 调速器上行方向:</span>
                              <span>
                                 <img :class="a4.Y16 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y17 调速器下行方向:</span>
                              <span>
                                 <img :class="a4.Y17 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y18 调速器运行使能:</span>
                              <span>
                                 <img :class="a4.Y18 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y19 调速器多段速端口1:</span>
                              <span>
                                 <img :class="a4.Y19 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y20 调速器多段速端口2:</span>
                              <span>
                                 <img :class="a4.Y20 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y21 调速器多段速端口3:</span>
                              <span>
                                 <img :class="a4.Y21 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">Y22 调速器多段速端口4:</span>
                              <span>
                                 <img :class="a4.Y22 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                          </div>
                          <div style="width:100%;margin:0 auto;">
                            <div class="info-item-title">轿厢板输出口状态</div>
                            <div class="info-item1">
                              <span class="title">GY0 前门开门按钮灯:</span>
                              <span>
                                <img :class="a4.GY0 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GY1 前门关门按钮灯:</span>
                              <span>
                                <img :class="a4.GY1 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GY2 前门开门保持按钮灯:</span>
                              <span>
                                <img :class="a4.GY2 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GY3 后门开门按钮灯:</span>
                              <span>
                                 <img :class="a4.GY3 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GY4 后门关门按钮灯:</span>
                              <span>
                                 <img :class="a4.GY4 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">GY5 后门开门保持按钮灯:</span>
                              <span>
                                 <img :class="a4.GY5 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                          </div>
                          <div style="width:100%;margin:0 auto;">
                            <div class="info-item-title">轿顶板输出口状态</div>
                            <div class="info-item1">
                              <span class="title">HY0 前门开门:</span>
                              <span>
                                <img :class="a4.HY0 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY1 前门关门:</span>
                              <span>
                                <img :class="a4.HY1 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY2 前门强迫关门:</span>
                              <span>
                                <img :class="a4.HY2 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY3 后门开门:</span>
                              <span>
                                 <img :class="a4.HY3 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY4 后门关门:</span>
                              <span>
                                 <img :class="a4.HY4 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY5 后门强迫关门:</span>
                              <span>
                                 <img :class="a4.HY5 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY6 照明:</span>
                              <span>
                                <img :class="a4.HY6 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY7 风扇:</span>
                              <span>
                                <img :class="a4.HY7 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY8 上到站钟:</span>
                              <span>
                                <img :class="a4.HY8 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY9 下到站钟:</span>
                              <span>
                                 <img :class="a4.HY9 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY10 强蜂鸣器:</span>
                              <span>
                                 <img :class="a4.HY10 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                            <div class="info-item1">
                              <span class="title">HY11 弱蜂鸣器:</span>
                              <span>
                                 <img :class="a4.HY11 ? 'image_light' : 'image_offlight'">
                              </span>
                            </div>
                          </div>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                </el-col>
              </el-row>
            </el-aside>
          </el-container>
        </div>
      </div>
      <div class="footer" style="color: #ddd;margin-top: 100px;">
        &nbsp;
      </div>
    </div>
    <ite-iot ref="iteIot" :ibe="IBE"></ite-iot>
  </div>
</template>
<script>
  import elevator from "./Elevator";
  import ElRow from "element-ui/packages/row/src/row";
  import ElCol from "element-ui/packages/col/src/col";
  import IteIot from "./IteIot";
  import * as echarts from "echarts";

  const wsUrl = window.config.wsUrl;
  const reconnectInterval = 3000; // 重试间隔时间,5秒
  const heartbeatTime = 9000; // 心跳间隔时间,90秒
  const pingProto = JSON.stringify({type: "101"}); // ping消息

  export default {
    components: {
      ElCol,
      ElRow,
      elevator,
      IteIot,
    },
    data() {
      return {
        copyright: window.config.copyright,
        editAuth: this.$auth(408),
        siteName: window.config.siteName,
        dialogVisible: false,
        submitLoading: false,
        submitLoading2: false,
        tabLoading: false,
        radioLoading: false,
        lockLoading: false,
        timer: "",
        timer2: "",
        timer3: "",
        timer4: "",
        isAC: "",
        lockStatus: "",
        activeName: "first",
        activeName_: "_first",
        activeName2: "first",
        loading: "",
        AAsuccess: "",
        ABsuccess: "",
        waitTime: 10000,
        ICData: {
          list: [],
        },
        inAuth: "0000000000000000000000000000000000000000000000000000000000000000", // 本地轿内指令
        outAuth: "0000000000000000000000000000000000000000000000000000000000000000", // 本地轿外指令
        netWorkInAuth: "", // 获取的网络轿内指令
        netWorkOutAuth: "", // 获取的网络轿外指令
        elevator: {
          dtuCode: "",
          nodeCode: "",
          floor: [],
        },
        connectState: "",
        connectMsg: "",
        ws: null,
        no: "",
        registerNo: "",
        brand: "",
        name: "",
        product: "",
        address: "",
        useUnitName: "",
        useUnitId: "",
        maintComName: "",
        maintEmpName: "",
        elevatorProductName: "",
        elevatorProductNameEn: "",
        nextYearCheckDate: "",
        nextMaintRecordDate: "",
        workOrderRecordTableData: [],
        maintRecordTableData: [],
        oldMaintRecordTableData: [],
        faultRecordTableData: [],
        presentCounterValue: "",
        totalRunningTime: "",
        totalRunDistance: "",
        wireropeBendingTimes: "",
        openTimes: "",
        doorOpenCloseTimes: "",
        Door_Lock_Status: "",
        Car_Direction: 0,
        Door_Status: false,
        Door_Open_Status: false,
        Car_Position: "",
        Door_Zone: false,
        Door_Switch_Status: -1,
        Service_Mode: "",
        Fault_Status: false,
        Fault_Code: 0,
        Safety_Circuit_Status: "",
        Car_Status: 0,
        Inner_Orders: "",
        Outer_Up_Orders: "",
        Outer_Down_Orders: "",
        useUnitElevators: [],
        currePage: 1,
        totalPage: 0,
        switchElevatorTag: false,
        Group_Control: false,
        Load_Status: 0,
        Run_Mode: 0,
        fireReturn: 0, // 消防返回
        earthquakeModel: 0,  // 地震模式
        emergencyPower: 0,  // 应急电源运行
        serviceable: 0,  // 电梯可服务
        mainPowerOff: 0,  // 主电源断电
        openButton: 0,  // 开门按钮
        remoteLock: 0,  // 远程锁梯
        netCtrl: 0,  // 联网控制
        referenceFloorOpenTimes: "",
        safeTouchActionTimes: "",
        lightActionTimes: "",
        carLightTimes: "",
        carLightRunningTime: "",
        upwardRelevelingTimes: "",
        downRelevelingTimes: "",
        runSpeed: "",
        reconnectNum: 3, // 重试次数
        lockReconnect: false, // 避免重复连接
        x22: false,
        x23: false,
        passengerStatus: false, // 轿厢内是否有人
        alarmBell: false, // 警铃报警
        currenStatus: "",
        tValue: "",
        beforeNum: 0,
        elevatorId: "",
        IBE: {},
        a4: {},
        showIBE: false,
        showA4: false,
        chartData: 0,
        speedValue: 0,
        myChart: null,
        option: {
          series: [
            {
              type: "gauge",
              startAngle: 180,
              endAngle: 0,
              min: 0,
              max: this.speedValue,
              splitNumber: 4,
              progress: {
                show: true,
                roundCap: true,
                width: 4,
              },
              axisLine: {
                lineStyle: {
                  width: 4,
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                length: 0,
                lineStyle: {
                  width: 0,
                  color: "#999",
                },
              },
              pointer: {
                offsetCenter: [0, "10%"],
                icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                length: "75%",
                itemStyle: {
                  color: "#5470c6",
                },
              },
              axisLabel: {
                distance: 1,
                color: "#000",
                fontSize: 8,
              },
              title: {
                show: false,
              },
              detail: {
                show: false,
              },
              data: [
                {
                  value: this.chartData,
                },
              ],
              animationEasingUpdate: "quadraticIn",  //数据更新时的缓动效果
              animationDurationUpdate: 2500,  //数据更新动画的时长
              animation: true, //开启动画
            }],
        },
      };
    },
    beforeDestroy() {
      clearTimeout(this.timer);
      clearTimeout(this.timer2);
      clearTimeout(this.timer3);
      clearTimeout(this.timer4);
    },
    mounted() {
      this.initChart();
    },
    methods: {
      chartSpeedRun(isRun) {
        this.option.series[0].max = this.speedValue;
        if (isRun == true && this.chartData == 0) {
          this.chartData = this.speedValue;
          this.option.series[0].data[0].value = this.chartData;
          this.myChart.setOption(this.option);
        }
        if (isRun == false && this.chartData == this.speedValue) {
          this.chartData = 0;
          this.option.series[0].data[0].value = this.chartData;
          this.myChart.setOption(this.option);
        }
      },
      initChart() {
        let chartDom = document.getElementById("chart");
        this.myChart = echarts.init(chartDom);
        this.option.series[0].data[0].value = this.chartData;
        this.option.series[0].max = this.speedValue;
        this.myChart.setOption(this.option);
      },
      getHM(time) {
        let day = parseInt(time / 60 / 60 / 24);
        let hour = parseInt(time / 60 / 60 % 24);
        let min = parseInt(time / 60 % 60);

        day = day > 9 ? day : "0" + day;
        hour = hour > 9 ? hour : "0" + hour;
        min = min > 9 ? min : "0" + min;

        // return day + "天" + hour + "时" + min + "分";
        return (time / 60 / 60).toFixed(2) + "小时";
      },
      getHMS(time) {
        let day = parseInt(time / 60 / 60 / 24);
        let hour = parseInt(time / 60 / 60 % 24);
        let min = parseInt(time / 60 % 60);
        let sec = parseInt(time % 60);

        day = day > 9 ? day : "0" + day;
        hour = hour > 9 ? hour : "0" + hour;
        min = min > 9 ? min : "0" + min;
        sec = sec > 9 ? sec : "0" + sec;

        return day + "天" + hour + "时" + min + "分" + sec + "秒";
      },
      init(data) {
        document.title = this.$t("route.singleMonitor");
        this.elevatorId = data.elevator.id;
        this.initPage(this.elevatorId, true);
      },
      setAC() {
        this.radioLoading = true;
        let param = {
          dtuCode: this.elevator.dtuCode,
          nodeCode: this.elevator.nodeCode,
          status: this.isAC,
        };
        this.$api.getData("remote/setAC", param).then(() => {
          this.$message.success("发送指令成功");
          //比对设置后的指令
          this.timer3 = setTimeout(() => {
            this.comparisonAC(this.isAC);
          }, this.waitTime);
        });
      },
      setLockStatus() {
        this.lockLoading = true;
        let param = {
          dtuCode: this.elevator.dtuCode,
          node: this.elevator.nodeCode,
          lockStatus: this.lockStatus,
        };
        console.log(param);
        this.$api.getData("elevators/lock", param).then(() => {
          this.$message.success("发送锁梯指令成功");
          //比对设置后的指令
          this.timer4 = setTimeout(() => {
            this.comparisonLockElevator(this.lockStatus);
          }, this.waitTime);
        }).catch(() => {
          this.lockLoading = false;
          this.$message.error("设置指令失败");
        });
      },
      comparisonAC(value) {
        if (value) {
          if (this.netCtrl === 1) {
            this.$message.success("设置指令成功");
          } else {
            this.$message.error("设置指令失败");
          }
        } else {
          if (this.netCtrl === 0) {
            this.$message.success("设置指令成功");
          } else {
            this.$message.error("设置指令失败");
          }
        }
        this.radioLoading = false;
        this.isAC = this.netCtrl === 1;
      },
      comparisonLockElevator(value) {
        if (value === 1) {
          if (this.remoteLock === 1) {
            this.$message.success("设置指令成功");
            this.lockStatus = this.remoteLock;
          } else {
            this.lockStatus = 0;
            this.$message.error("设置指令失败");
          }
        } else {
          if (this.remoteLock === 0) {
            this.$message.success("设置指令成功");
            this.lockStatus = this.remoteLock;
          } else {
            this.lockStatus = 1;
            this.$message.error("设置指令失败");
          }
        }
        this.lockLoading = false;
      },
      changeTabs(tab, event) {
        if (tab.name === "_second") {
          this.$api.getById("singleLadderMonitor", this.elevatorId).then(res => {
            this.workOrderRecordTableData = res.data.workOrderRecordVos;
          }).catch((e) => {
            this.$message.error("获取数据失败");
          });
        }
        if (tab.name === "_fourth") {
          this.$api.getById("singleLadderMonitor", this.elevatorId).then(res => {
            this.faultRecordTableData = res.data.faultRecordVos;
          }).catch((e) => {
            this.$message.error("获取数据失败");
          });
        }
      },
      getICData() {
        this.tabLoading = true;
        this.submitLoading = true;
        let param = {
          dtuCode: this.elevator.dtuCode,
          nodeCode: this.elevator.nodeCode,
        };
        this.$api.getData("remote/getCallAuthority", param).then(() => {
          this.$message.success("发送读取指令成功");
          // 10秒后比对获取的指令设置
          this.timer = setTimeout(() => {
            this.getICDataList();
          }, this.waitTime);
        });
      },
      //发送AA AB协议后对比结果
      comparisonICData() {
        let param = {
          dtuCode: this.elevator.dtuCode,
          nodeCode: this.elevator.nodeCode,
        };
        this.$api.getData("remote/getCallAuthority", param).then(() => {
          // 10秒后比对获取的指令设置
          this.timer2 = setTimeout(() => {
            this.comparisonICDataList();
          }, this.waitTime);
        });
      },
      allOpen() {
        for (let item of this.elevator.floor) {
          this.inAuth = this.inAuth.substring(0, item.index) + "1" + this.inAuth.substring(item.index + 1);
          this.outAuth = this.outAuth.substring(0, item.index) + "1" + this.outAuth.substring(item.index + 1);
        }
        this.initICDataList();
      },
      allClose() {
        this.inAuth = "0000000000000000000000000000000000000000000000000000000000000000";
        this.outAuth = "0000000000000000000000000000000000000000000000000000000000000000";
        this.initICDataList();
      },
      getICDataList() {
        this.ICData.list = [];
        if (this.netWorkInAuth === undefined || (this.netWorkInAuth.length !== 64 && this.netWorkOutAuth.length !== 64)) {
          this.tabLoading = false;
          this.submitLoading = false;
          this.initICDataList();
          return this.$message.error("获取当前设置失败！");
        } else {
          this.inAuth = this.netWorkInAuth;
          this.outAuth = this.netWorkOutAuth;
        }
        for (let item of this.elevator.floor) {
          let param = {
            WLfloor: item.value,
            XSfloor: item.displayValue,
            inValue: this.inAuth.substring(item.index, item.index + 1),
            outValue: this.outAuth.substring(item.index, item.index + 1),
            index: item.index,
          };
          this.ICData.list.push(param);
        }
        console.log(this.ICData.list);
        this.tabLoading = false;
        this.submitLoading = false;
      },
      comparisonICDataList() {
        this.ICData.list = [];
        if (this.AAsuccess === 1 && this.ABsuccess === 1) {
          this.$message.success("保存当前设置成功！");
          if (this.netWorkInAuth !== this.inAuth || this.outAuth !== this.netWorkOutAuth) {
            this.tabLoading = false;
            this.submitLoading2 = false;
            this.initICDataList();
            return this.$message.error("保存当前设置失败！");
          }
        } else {
          this.tabLoading = false;
          this.submitLoading2 = false;
          this.initICDataList();
          return this.$message.error("保存当前设置失败！");
        }
        if (this.netWorkInAuth === undefined || (this.netWorkInAuth.length !== 64 && this.netWorkOutAuth.length !== 64)) {
          this.tabLoading = false;
          this.submitLoading2 = false;
          this.initICDataList();
          return this.$message.error("获取当前设置失败！");
        } else {
          this.inAuth = this.netWorkInAuth;
          this.outAuth = this.netWorkOutAuth;
        }
        for (let item of this.elevator.floor) {
          let param = {
            WLfloor: item.value,
            XSfloor: item.displayValue,
            inValue: this.inAuth.substring(item.index, item.index + 1),
            outValue: this.outAuth.substring(item.index, item.index + 1),
            index: item.index,
          };
          this.ICData.list.push(param);
        }
        console.log(this.ICData.list);
        this.tabLoading = false;
        this.submitLoading2 = false;
      },
      initICDataList() {
        this.ICData.list = [];
        if (this.elevator.floor.length < 1) {
          return this.$message.error("当前楼层未配置");
        }
        if (this.elevator.floor.length > 64) {
          return this.$message.error("当前楼层高于64");
        }
        for (let item of this.elevator.floor) {
          let param = {
            WLfloor: item.value,
            XSfloor: item.displayValue,
            inValue: this.inAuth.substring(item.index, item.index + 1),
            outValue: this.outAuth.substring(item.index, item.index + 1),
            index: item.index,
          };
          this.ICData.list.push(param);
        }
        console.log(this.ICData.list);
        this.tabLoading = false;
      },
      saveICData() {
        this.tabLoading = true;
        this.submitLoading2 = true;
        console.log(this.inAuth);
        console.log(this.ICData.list);
        for (let item of this.ICData.list) {
          this.inAuth = this.inAuth.substring(0, item.index) + item.inValue + this.inAuth.substring(item.index + 1);
          this.outAuth = this.outAuth.substring(0, item.index) + item.outValue + this.outAuth.substring(item.index + 1);
        }
        let param = {
          dtuCode: this.elevator.dtuCode,
          nodeCode: this.elevator.nodeCode,
          internalAuthority: this.inAuth,
          externalAuthority: this.outAuth,
        };
        console.log(param);
        this.$api.getData("remote/setCallAuthority", param).then(() => {
          this.$message.success("发送保存指令成功");
          //发送AA AB协议后对比结果
          this.comparisonICData();
        });
      },
      openDialog() {
        this.ICData.list = [];
        this.dialogVisible = true;
        this.initICDataList();
      },
      dialogClose() {
        this.ICData.list = [];
      },
      initPage(elevatorId, isInit) {
        this.loading = true;
        this.$api.getById("singleLadderMonitor", elevatorId).then(res => {
          this.loading = false;
          let data = res.data;
          console.log(data);
          this.no = data.elevator.no;
          this.registerNo = data.elevator.regNo;
          this.brand = data.elevator.brand;
          this.name = data.elevator.name;
          this.product = data.elevator.model;
          this.address = data.elevator.address;
          this.useUnitName = data.elevator.useUnitName;
          this.maintComName = data.elevator.maintComName;
          this.maintEmpName = data.elevator.maintEmpName;
          this.elevatorProductName = data.elevator.elevatorProductTypeName;
          this.elevatorProductNameEn = data.elevator.elevatorProductTypeNameEn;
          this.useUnitId = data.elevator.useUnitId;
          this.nextYearCheckDate = data.nextYearCheckDate;
          this.nextMaintRecordDate = data.nextMaintRecordDate;
          this.workOrderRecordTableData = data.workOrderRecordVos;
          this.maintRecordTableData = data.maintRecordVos;
          this.oldMaintRecordTableData = data.oldMaintRecordVos;
          this.faultRecordTableData = data.faultRecordVos;
          this.speedValue = data.elevator.ratedSpeed;
          //alert(this.speedValue);
          this.initChart();
          this.elevator.floor = data.elevator.elevatorFloors;
          this.elevator.dtuCode = data.elevator.dtuCode;
          this.elevator.nodeCode = data.elevator.nodeCode;
          if (isInit) {
            this.getList(1);
            this.getTerminalData();
          }
          this.createWs();
        }).catch(() => {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      getTerminalData() {
        let params = {
          dtuCode: this.elevator.dtuCode,
        };
        this.$api.getData("devices/redisDeviceInfo", params).then(res => {
          console.log(res.data);
          let data = res.data;
          if (data.softwareVersion == "1.2.3") {
            this.showIBE = true;
          }
        }).catch(() => {
        });
      },
      createWs() {
        this.connectState = "开始连接";
        try {
          this.ws = new WebSocket(wsUrl);
          this.initEventHandle();
        } catch (e) {
          this.reconnectWs();
        }
      },
      reconnectWs() {
        if (!this.lockReconnect) {
          this.lockReconnect = true;
          // 没连接上会一直重连，设置延迟避免请求过，自动重连三次
          setTimeout(() => {
            if (this.reconnectNum > 0) {
              this.reconnectNum--;
              this.createWs();
              this.lockReconnect = false;
            }
          }, reconnectInterval);
        }
      },
      initEventHandle() {
        this.ws.onclose = () => {
          // console.log('onclose')
          this.Car_Position = "";
          this.connectState = "连接断开";
          //this.reconnectWs();
        };
        this.ws.onerror = () => {
          // console.log('onerror')
          this.connectState = "连接异常";
          this.reconnectWs();
        };
        this.ws.onopen = () => {
          this.connectState = "开始认证";
          //this.heartbeatReset() // 心跳检测重置
          //{"t":"B00","d":{"regCode":"DT100000004031"}}
          let authProto = {
            t: "B00",
            d: {
              regCode: this.registerNo,
              //regCode: 'ZC00000464'
            },
          };
          this.ws.send(JSON.stringify(authProto)); // 发送认证信息
        };
        this.ws.onmessage = (event) => {
          try {
            let proto = JSON.parse(event.data);
            this.tValue = proto.t;
            //console.log("Remote_Lock:" + event.data.toString());
            if (this.tValue === "A05" || this.tValue === "A09") {
              this.reconnectNum = 3;
            }
            // console.log('monitorData', event.data)
            if (this.tValue === "A09") {
              //console.log(proto.d);
              //console.log("A09");
              if (proto.d.IBE) {
                this.IBE = proto.d.IBE;
              }
              if (proto.d.A4) {
                this.a4 = proto.d.A4;
                this.showA4 = true;
              } else {
                this.showA4 = false;
              }
              //console.log("Fault_Code:" + proto.d.Fault_Code + "   _Door_Zone:" + proto.d.Door_Zone + "   _Car_Status:" + proto.d.Car_Status);
              //console.log("Remote_Lock:" + proto.d.Remote_Lock, "netCtrl:" + proto.d.Net_Ctrl);
              this.presentCounterValue = proto.d.Present_Counter_Value;
              this.totalRunningTime = proto.d.Total_Running_Time;
              this.totalRunDistance = proto.d.Total_Run_Distance;
              this.wireropeBendingTimes = proto.d.Wirerope_Bending_Times;
              this.openTimes = proto.d.Door_Open_Times;
              this.doorOpenCloseTimes = proto.d.Door_Open_Close_Times;
              this.Car_Position = proto.d.Car_Position;
              this.Car_Direction = proto.d.Car_Direction;
              this.Door_Zone = proto.d.Door_Zone;
              this.Door_Status = proto.d.Door_Status;
              this.Door_Open_Status = proto.d.Door_Open_Status;
              this.Door_Switch_Status = proto.d.Door_Switch_Status;
              this.Door_Lock_Status = proto.d.Door_Lock_Status;
              this.Service_Mode = proto.d.Service_Mode;
              this.Fault_Status = proto.d.Fault_Status;
              this.Fault_Code = proto.d.Fault_Code;
              this.Safety_Circuit_Status = proto.d.Safety_Circuit_Status;
              this.Car_Status = proto.d.Car_Status;

              if (this.Car_Status === 0) {
                this.chartSpeedRun(false);
              } else {
                this.chartSpeedRun(true);
              }

              this.Inner_Orders = proto.d.Inner_Orders;
              this.Outer_Up_Orders = proto.d.Outer_Up_Orders;
              //this.Outer_Up_Orders = [2,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3];
              this.Outer_Down_Orders = proto.d.Outer_Down_Orders;
              this.Group_Control = proto.d.Group_Control;
              this.passengerStatus = proto.d.Passenger_Status;
              this.alarmBell = proto.d.Alarm_Bell;
              this.x22 = proto.d.X22;
              this.x23 = proto.d.X23;
              this.Load_Status = proto.d.Load_Status;
              this.Run_Mode = proto.d.Run_Mode;
              this.fireReturn = proto.d.Fire_Return;
              this.earthquakeModel = proto.d.Earthquake_Model;
              this.emergencyPower = proto.d.Emergency_Power;
              this.serviceable = proto.d.Serviceable;
              this.mainPowerOff = proto.d.Main_Power_Off;
              this.openButton = proto.d.Open_Button;
              this.remoteLock = proto.d.Remote_Lock;
              this.netCtrl = proto.d.Net_Ctrl;
              this.referenceFloorOpenTimes = proto.d.Reference_Floor_Open_Times;
              this.safeTouchActionTimes = proto.d.Safe_Touch_Action_Times;
              this.lightActionTimes = proto.d.Light_Action_Times;
              this.carLightTimes = proto.d.Car_Light_Times;
              this.carLightRunningTime = proto.d.Car_Light_Running_Time;
              this.upwardRelevelingTimes = proto.d.Upward_Releveling_Times;
              this.downRelevelingTimes = proto.d.Down_Releveling_Times;
              this.runSpeed = proto.d.Run_Speed;

              if (this.lockStatus === "") {
                this.lockStatus = proto.d.Net_Ctrl;
              }
              if (this.isAC === "") {
                this.isAC = proto.d.Remote_Lock === 1;
              }
              //console.log(this.isAC, this.lockStatus);
              this.connectState = "连接正常";
              switch (this.Service_Mode) {
                case 1:
                  this.currenStatus = this.$t("monitor.status.motion");
                  break;
                case 2:
                  this.currenStatus = this.$t("monitor.status.service");
                  break;
                case 3:
                  this.currenStatus = this.$t("monitor.status.fireReturn");
                  break;
                case 4:
                  this.currenStatus = this.$t("monitor.status.fire");
                  break;
                case 5:
                  this.currenStatus = this.$t("monitor.status.emergencyPower");
                  break;
                case 6:
                  this.currenStatus = this.$t("monitor.status.earthquakeModel");
                  break;
                case 7:
                  this.currenStatus = this.$t("monitor.status.unKnow");
                  break;
                case 8:
                  this.currenStatus = this.$t("monitor.status.safety");
                  break;
                case 9:
                  this.currenStatus = this.$t("monitor.status.fault");
                  break;
                case 10:
                  this.currenStatus = this.$t("monitor.status.Lock");
                  break;
                case 11:
                  this.currenStatus = this.$t("monitor.status.independence");
                  break;
                case 12:
                  this.currenStatus = this.$t("monitor.status.driver");
                  break;
              }
            } else if (this.tValue === "A05") {
              this.beforeNum = proto.d.beforeNum;
            }
            if (this.tValue === "AF") {
              console.log(proto.d);
              this.netWorkInAuth = proto.d.inAuth;
              this.netWorkOutAuth = proto.d.outAuth;
            }
            if (this.tValue === "AA") {
              console.log("获取AA指令成功：");
              console.log(proto.d);
              this.AAsuccess = proto.d.success;
            }
            if (this.tValue === "AB") {
              console.log("获取AB指令成功：");
              console.log(proto.d);
              this.ABsuccess = proto.d.success;
            }
          } catch (e) {
            console.log(e);
          }
        };
      },
      heartbeatStart() {
        this.timeoutObj = setTimeout(() => {
          // 这里发送一个心跳,后端收到后,返回一个心跳消息,onmessage拿到返回的心跳就说明连接正常
          this.ws.send(pingProto);
        }, heartbeatTime);
      },
      heartbeatReset() {
        // console.log('reset')
        clearTimeout(this.timeoutObj);
        this.heartbeatStart();
      },
      requestData(type) {
        // console.log('请求数据：' + type)
        let requestProto = {
          type: type,
        };
        this.ws.send(JSON.stringify(requestProto));
      },
      changeElevator() {
        this.switchElevatorTag = !this.switchElevatorTag;
      },
      closeChangeElevator() {
        this.switchElevatorTag = false;
      },
      getList(pageIndex) {
        this.loading = true;
        let params = {
          "pageNo": pageIndex,
          "useUnitId": this.useUnitId,
          "pageSize": 8,
        };
        this.$api.getList("/singleLadderMonitor", params).then(response => {
          this.loading = false;
          let data = response.data;
          console.log(data);
          this.useUnitElevators = data.records;
          for (let i = 0; i < this.useUnitElevators.length; i++) {
            this.useUnitElevators[i].tag = 0;
          }
          this.total = data.total;
          this.totalPage = Math.ceil(data.total / 8);
        }).catch(() => {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      findNext() {
        this.currePage = this.currePage + 1;
        this.getList(this.currePage);
      },
      findLast() {
        this.currePage = this.currePage - 1;
        this.getList(this.currePage);
      },
      selectElevator(id, index) {
        this.presentCounterValue = "";
        this.totalRunningTime = "";
        this.totalRunDistance = "";
        this.wireropeBendingTimes = "";
        this.openTimes = "";
        this.doorOpenCloseTimes = "",
          this.Car_Position = "";
        this.Car_Direction = 0;
        this.Door_Zone = true;
        this.Door_Status = false;
        this.Door_Open_Status = false;
        this.Door_Switch_Status = 0;
        this.Door_Lock_Status = "";
        this.Service_Mode = "";
        this.Fault_Status = "";
        this.Safety_Circuit_Status = "";
        this.Car_Status = 0;
        this.Inner_Orders = [];
        this.Outer_Up_Orders = [];
        this.Outer_Down_Orders = [];
        this.Group_Control = false;
        this.Load_Status = 0;
        this.Run_Mode = 0;
        this.currenStatus = "";
        this.fireReturn = 0;
        this.earthquakeModel = 0;
        this.emergencyPower = 0;
        this.serviceable = 0;
        this.mainPowerOff = 0;
        this.openButton = 0;
        this.netCtrl = 0;
        this.remoteLock = 0;
        this.passengerStatus = false;
        this.alarmBell = false;
        this.x22 = false;
        this.x23 = false;
        for (let i = 0; i < this.useUnitElevators.length; i++) {
          this.useUnitElevators[i].tag = 0;
          if (i === index) {
            this.useUnitElevators[i].tag = 1;
          }
        }
        this.ws.close();
        this.initPage(id, false);
      },
      reConnect() {
        this.createWs();
      },
    },
  };
</script>
<style lang="scss" scoped>
@import "../../style/monitorSingle.css";

.querypage1 {
  width: 1400px;
  margin: 0 auto;
  position: relative;
}

.monitorcontainer1 {
  clear: both;
  overflow: hidden;
  background-color: white;
  width: 1400px;
  height: 650px;
}

.middleLoadingClass {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.infobg {
  position: absolute;
  left: 498px;
  top: 105px;
  width: 887px;
  height: 652px;
}

.changeButton {
  position: absolute;
  background-color: transparent;
  background-image: url(/static/images/elevator/changebtns1.png);
  border: none;
  color: #909399;
  left: 650px;
  bottom: 6%;
  width: 154px;
  height: 47px;
  z-index: 3;
}

.switchElevatorDiv {
  position: absolute;
  z-index: 25;
  border: 0px solid;
  background-color: #FFFFFF;
  top: 690px;
  width: 1380px;
  height: 180px;
}

.pageRight {
}

.elevtable td {
  text-align: center;
  width: 80px;
}

.singleMonitortable {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.singleMonitortable .Monitor {
  width: 70px;
  margin-right: 2px;
  text-align: center;
  margin-top: 1px;
}

.item3 {
  font-size: 31px !important;
  color: #1296db !important;
}

.item2 {
  font-size: 31px !important;
  color: #cccccc !important;
}

.monitorFont2 {
  font-size: 12px;
}

.monitorFont3 {
  color: #368de6;
  font-size: 12px;
}

.frashButton {
  position: absolute;
  left: 306px;
  top: 422px;
  z-index: 34;
}

.pointer {
  cursor: pointer;
}

.call {
  background: url(/static/images/elevator/callbg.png) no-repeat;
  margin-top: -2px;
  background-size: 65% 90%;
  padding: 5% 0;
  color: #f8f8f8;
}

.call-zh {
  display: block;
  width: 105px;
  margin-top: -9px;
  font-size: 15px;
  text-align: center;
  letter-spacing: 10px
}

.call-en {
  display: block;
  width: 91px;
  margin-top: -9px;
  font-size: 14px;
  text-align: center;
}

.call-zh1 {
  margin-top: 6px;
  line-height: 28px;
  display: block;
  width: 90px;
  font-size: 13px;
  text-align: center;
  color: white;
  background-color: #63a35c;
}

.call-en1 {
  margin-top: 6px;
  line-height: 28px;
  display: block;
  width: 90px;
  font-size: 13px;
  text-align: center;
  color: white;
  background-color: #63a35c;
}

.call-floor {
  //padding:0 3%;
  height: 28px;
  width: 28px;
  border-radius: 15px;
  text-align: center;
  line-height: 28px;
  color: #000000;
  border: 1px solid #0cea3b;
  margin-top: 5px;
  margin-left: 5px;
  float: left;
  font-size: 13px;
}

.info-item {
  padding: 10px;
  border-bottom: 1px dotted #ccc;
  font-size: 14px;
  color: #333;

  .title {
    color: #888;
  }

  .font_right {
    float: right;
  }

  .image_light {
    content: url("/static/images/elevator/onlight.png");
    width: 20px;
    height: 20px;
    float: right;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .image_offlight {
    content: url("/static/images/elevator/offlight.png");
    width: 20px;
    height: 20px;
    float: right;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

.info-item-title {
  padding-top: 20px;
  width: 100%;
  float: left;
}

.info-item1 {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc;
  font-size: 14px;
  color: #333;
  width: 48%;
  float: left;
  margin-right: 2%;

  .title {
    color: #888;
    padding: 10px;
  }

  .font_right {
    float: right;
  }

  .image_light {
    content: url("/static/images/elevator/onlight.png");
    width: 20px;
    height: 20px;
    float: right;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .image_offlight {
    content: url("/static/images/elevator/offlight.png");
    width: 20px;
    height: 20px;
    float: right;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

.wrap {

  height: 100vh;

  .fault {
    width: 20%;
    height: 25px;
    margin: 0px 2.5% 10px 2.5%;
    float: left;
    color: #333;
    font-size: 13px;
    text-align: center;
  }

  .faultEn {
    width: 90%;
    height: 20px;
    margin: 12px 2.5% 10px 2.5%;
    color: #333;
    font-size: 14px;
  }
}
</style>
